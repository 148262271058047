import Cookies from "js-cookie";

// Function to get permissions from cookies
const getUserPermissions = () => {
    const permissions = Cookies.get("userPermissions");
    return permissions ? JSON.parse(permissions) : [];
};

// Function to check if a user has permission
export const hasPermission = (module: string, action: string) => {
    const permissions = getUserPermissions();
    const modulePermissions = permissions.find((perm: any) => perm.name === module);
    return modulePermissions ? modulePermissions.access.includes(action) : false;
};
