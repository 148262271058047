import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { get, deleteRequest } from "./../../../../networkConfig/Netwok.js";
import { NOTES } from "./../../../../networkConfig/API_CONSTANTS.js";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import DeleteAlert from "./../../../app/components/alerts/DeleteAlerts.js";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const NotesTab = ({ id, source, sourceKey }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [transactionData, setTransactionData] = useState({
    data: [],
    pagination: { totalItems: 0 },
  });
  const [apiError, setApiError] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();

  if (loading === true) {
    setLoading(false);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when changing rows per page
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDelete = (index) => {
    setSelectedRow(index);
    setAnchorEl(null); // Ensure the dropdown menu closes
    setModalIsOpen(true); // Open the delete modal
  };

  const onDelete = async () => {
    try {
      const deleteData = {
        id: transactionData.data[selectedRow]._id,
      };
      const response = await deleteRequest(NOTES, deleteData);
      if (response.code === 200) {
        setModalIsOpen(false); // Close the modal
        setAnchorEl(null); // Close the menu
        setSelectedRow(null); // Reset the selected row
        fetchTransactionData(); // Refetch data
      } else {
        window.alert(response.error || response.code);
      }
    } catch (error) {
      console.error("Error deleting Transaction:", error);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value); // Just update searchTerm, no direct API call
  };

  const fetchTransactionData = useCallback(
    async (searchTerm = "") => {
      if (!id) return;
      try {
        setLoading(true);
        const tempQuery =
          searchTerm.length > 2 ? `/search?q=${searchTerm}&` : "?";
        const url = `${NOTES}${tempQuery}${sourceKey}=${id}&page=${
          page + 1
        }&pageSize=${rowsPerPage}`;
        const result = await get(url);
        if (
          result.pagination?.pageSize &&
          result.pagination.pageSize !== rowsPerPage
        ) {
          setRowsPerPage(result.pagination.pageSize); // Update rowsPerPage from the API response
        }
        if (result.code === 200) {
          setTransactionData({
            data: Array.isArray(result.data) ? result.data : [result.data],
            pagination: result.pagination || {
              currentPage: 1,
              pageSize: 10,
              totalItems: 0,
              totalPages: 1,
            },
          });
        } else {
          setTransactionData([]); // Clear on error
        }
        setApiError(false);
      } catch (error) {
        console.error("Error fetching transactions:", error);
        setApiError(true);
      } finally {
        setLoading(false);
      }
    },
    [
      id,
      page,
      rowsPerPage,
      sourceKey,
      setRowsPerPage,
      setTransactionData,
      setApiError,
    ]
  );

  useEffect(() => {
    if (searchTerm) {
      fetchTransactionData(searchTerm);
    } else {
      fetchTransactionData(""); // Call fetchPlots with an empty searchTerm if needed (for resetting the search)
    }
  }, [page, rowsPerPage, searchTerm, id, fetchTransactionData]);

  const getDisplayRange = () => {
    const start = page * rowsPerPage + 1;
    const end = Math.min(
      (page + 1) * rowsPerPage,
      transactionData.pagination?.totalItems || 0
    );
    const total = transactionData.pagination?.totalItems || 0;
    return `${start}-${end} of ${total}`;
  };

  const handleAdd = () => {
    navigate(`/${source}/${id}/notes/${source}/${sourceKey}/add`);
  };

  const handleEdit = (index) => {
    navigate(`/${source}/${id}/notes/${source}/edit/${index}`);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="right" alignItems="right" mb={2}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleAdd}
          style={{ minWidth: "150px" }} // Prevents shrinking
        >
          + Add Notes
        </Button>
        <input
          type="hidden"
          placeholder="Search by name"
          onChange={handleSearch}
          style={{
            flex: 1, // Allows input to take space and shift toward center
            padding: "8px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            minWidth: "200px",
            maxWidth: "400px",
            marginRight: "16px",
          }}
        />
      </Box>
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell align="center">Notes</TableCell>
            <TableCell align="center">Time Log</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactionData.data.length > 0 ? (
            transactionData.data.map((transaction, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <Typography className="font-bold">
                    {transaction.notes || "-"}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{
                      fontSize: "11.5px",
                      fontStyle: "italic",
                      lineHeight: "1.5",
                    }}
                  >
                    Created:{" "}
                    {format(
                      new Date(transaction.createdAt),
                      "dd MMMM, yyyy hh:mma"
                    ).toLowerCase()}
                    <br />
                    Updated:{" "}
                    {format(
                      new Date(transaction.updatedAt),
                      "dd MMMM, yyyy hh:mma"
                    ).toLowerCase()}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <div className="cursor-pointer z-6">
                    <EllipsisVerticalIcon
                      style={{ width: "16px", height: "16px" }}
                      onClick={(event) => handleOpenMenu(event, index)}
                    />
                    <Menu
                      anchorEl={anchorEl}
                      open={selectedRow === index && Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={() => handleEdit(transaction._id)}>
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => handleDelete(index)}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <Typography>No transactions found.</Typography>
          )}
        </TableBody>
      </StyledTable>
      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={transactionData.pagination?.totalItems || 0}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={() => getDisplayRange()} // Custom range display
      />
      {apiError && <Typography>Error fetching data.</Typography>}
      <DeleteAlert
        isOpen={modalIsOpen}
        onDelete={onDelete}
        onCancel={() => {
          setModalIsOpen(false);
          setAnchorEl(null); // Ensure dropdown closes when modal is canceled
          setSelectedRow(null); // Reset selected row
        }}
      />
    </Box>
  );
};

export default NotesTab;
