import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, CircularProgress } from "@mui/material";
import { Breadcrumb, SimpleCard } from "app/components";
import { Container } from "app/utils/Container";
import { useParams } from "react-router-dom";
import { ENQUIRY } from "../../../networkConfig/API_CONSTANTS";
import { get } from "../../../networkConfig/Netwok.js";

// Import individual tab components
import ProfileTab from "./tabs/ProfileTab";
import NotesTab from "./tabs/NotesTab.js";

const ViewEnquiry = () => {
  const params = useParams();
  const [enquiryId, setEnquiryId] = useState("");

  const [enquiryData, setEnquiryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  const updateEquiryData = (enquiryData) => {
    setEnquiryData(enquiryData); // Update state when user details change
  };

  useEffect(() => {
    setEnquiryId(params.index || "");

    const fetchEnquiryData = async () => {
      try {
        const response = await get(`${ENQUIRY}/?id=${enquiryId}`);
        if (response.code === 200 && response.data.length > 0) {
          setEnquiryData(response.data[0]);
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (enquiryId) {
      fetchEnquiryData();
    }
  }, [enquiryId, params]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Container>
      <Box>
        {/* Breadcrumb */}
        <Box
          className="breadcrumb"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Breadcrumb
            routeSegments={[
              { name: "Enquriy", path: `/Enquriy/${enquiryData._id}` },
              { name: enquiryData.name },
            ]}
          />
        </Box>

        <SimpleCard title={`Enquiry Details`}>
          {/* Tabs Navigation */}

          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
          >
            <Tab label="Details" />
            <Tab label="Notes" />
          </Tabs>

          {/* Tabs Content */}
          <Box sx={{ mt: 3 }}>
            {activeTab === 0 && (
              <ProfileTab
                enquiryData={enquiryData}
                updateEnquiyData={updateEquiryData}
              />
            )}
            {activeTab === 1 && (
              <NotesTab
                id={enquiryData._id}
                source="leads"
                sourceKey="enquiryId"
              />
            )}
          </Box>
        </SimpleCard>
      </Box>
    </Container>
  );
};

export default ViewEnquiry;
