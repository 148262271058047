import React from "react";
import Modal from "react-modal";
const DeleteAlert = ({ onDelete, onCancel, isOpen }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
    },
  };
  return (
    <Modal style={customStyles} isOpen={isOpen}>
      <div className="delete-alert">
        <h2 className="text-center">
          Do you really want to <br />
          delete this record?
        </h2>
        <div
          className="delete-box d-flex justify-content-center align-items-center"
          style={{ paddingLeft: "50px", paddingRight: "20px" }}
        >
          <img
            alt="DELETE"
            src="./../../../assets/images/7952.jpg"
            className="img-fluid"
            style={{ height: "200px", width: "200px" }}
          />
        </div>
        <div className="button-container d-flex justify-content-center">
          <button
            className="btn btn-danger mx-2"
            style={{
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              padding: "5px 50px",
              borderRadius: "4px",
              margin: "5px 2px 5px 0",
              fontSize: "14px",
            }}
            onClick={onDelete}
          >
            Delete
          </button>
          <button
            className="btn btn-secondary mx-2"
            style={{
              backgroundColor: "#FF0008",
              color: "white",
              border: "none",
              padding: "5px 50px",
              borderRadius: "4px",
              margin: "5px 2px 5px 0",
              fontSize: "14px",
            }}
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default DeleteAlert;
