export const API_URL = "https://dev-plot-api.stayflock.com/";
export const PLOT_URL = "plots";
export const SITES_URL = "projects";
export const CUSTOMER = "customer-broker";
export const TRANSACTION = "customer-broker/transcations";
export const FAVOURITES = "plots/favorite";
export const NOTES = "notes";
export const DOCUMENTS = "documents";
export const PERMISSIONS = "customer-broker/permission";
export const LOGIN = "users/adminLogin";
export const ENQUIRY = "enquiry";
export const UPLOAD = "upload";
export const HOME_ADMIN = "home/admin";
export const HOME_APP = "home";
export const GET_ADMIN_PROFILE_URL = "users/getAdminTokenProfile";
