import React from "react";
import { TheHousingHubSuspense } from "app/components";
import useSettings from "app/hooks/useSettings";
import { TheHousingHubLayouts } from "./index";

export default function TheHousingHubLayout(props) {
  const { settings } = useSettings();
  const Layout = TheHousingHubLayouts[settings.activeLayout];

  return (
    <TheHousingHubSuspense>
      <Layout {...props} />
    </TheHousingHubSuspense>
  );
}
