import { lazy } from "react";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "./protectedRoute";
import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Loadable from "./components/Loadable";
import TheHousingHubLayout from "./components/TheHousingHubLayout/TheHousingHubLayout";

import materialRoutes from "app/views/material-kit/MaterialRoutes";
import ViewEnquiry from "./views/crm/enquiry/viewEnquiry";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(
  lazy(() => import("app/views/sessions/JwtRegister"))
);
const ForgotPassword = Loadable(
  lazy(() => import("app/views/sessions/ForgotPassword"))
);
// const Sites = Loadable(lazy(() => import("app/views/master/sites/sites")));
// E-CHART PAGE
const AppEchart = Loadable(
  lazy(() => import("app/views/charts/echarts/AppEchart"))
);
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
const AddPlot = Loadable(lazy(() => import("app/views/master/plots/addPlots")));
const EditPlotForm = Loadable(
  lazy(() => import("app/views/master/plots/editPlots"))
);
const ViewPlots = Loadable(
  lazy(() => import("app/views/master/plots/viewPlots"))
);
const AddSites = Loadable(
  lazy(() => import("app/views/master/sites/addSites"))
);
const EditSites = Loadable(
  lazy(() => import("app/views/master/sites/editSites"))
);
const AddUsers = Loadable(
  lazy(() => import("app/views/crm/users/addUsers.js"))
);
const EditUsers = Loadable(
  lazy(() => import("app/views/crm/users/editUsers.js"))
);
const ViewUsers = Loadable(
  lazy(() => import("app/views/crm/users/viewUsers.js"))
);
const AddDocument = Loadable(
  lazy(() => import("app/views/crm/users/tabs/documents/addDocuments.js"))
);
const EditDocument = Loadable(
  lazy(() => import("app/views/crm/users/tabs/documents/editDocuments.js"))
);
const AddNotes = Loadable(
  lazy(() => import("app/views/crm/users/tabs/notes/addNotes.js"))
);
const EditNotes = Loadable(
  lazy(() => import("app/views/crm/users/tabs/notes/editNotes.js"))
);
const AddEnquiry = Loadable(
  lazy(() => import("app/views/crm/enquiry/addEnquiry.js"))
);
// const EditEnquiry = Loadable(lazy(() => import("app/views/crm/enquiry/editEnquiry.js")));
const AddTransaction = Loadable(
  lazy(() => import("app/views/transaction/addTransaction.js"))
);
const EditTransaction = Loadable(
  lazy(() => import("app/views/transaction/editTransaction.js"))
);

const EnquiryAddNotes = Loadable(
  lazy(() => import("app/views/crm/enquiry/tabs/notes/addNotes"))
);

const EnquiryEditNotes = Loadable(
  lazy(() => import("app/views/crm/enquiry/tabs/notes/editNotes"))
);

const routes = [
  {
    element: (
      <AuthGuard>
        <TheHousingHubLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: "/dashboard/default",
        element: (
          <ProtectedRoute module="admin" action="VIEW">
            <Analytics />
          </ProtectedRoute>
        ),
        auth: authRoles.admin,
      },
      // e-chart route
      {
        path: "/charts/echarts",
        element: <AppEchart />,
        auth: authRoles.editor,
      },
      {
        path: "app/views/dashboard/Analytics",
        auth: <authRoles className="guest"></authRoles>,
      },
      // Plots
      {
        path: "/plots/add",
        element: (
          <ProtectedRoute module="plot" action="ADD">
            <AddPlot />
          </ProtectedRoute>
        ),
      },
      {
        path: "/plots/edit/:index",
        element: (
          <ProtectedRoute module="plot" action="EDIT">
            <EditPlotForm />
          </ProtectedRoute>
        ),
      },
      {
        path: "/plots/:index",
        element: (
          <ProtectedRoute module="plot" action="VIEW">
            <ViewPlots />
          </ProtectedRoute>
        ),
      },
      {
        path: "/plots/:plotId/document/add",
        element: (
          <ProtectedRoute module="plot" action="VIEW">
            <AddDocument />
          </ProtectedRoute>
        ),
      },
      {
        path: "/plots/:plotId/document/edit/:documentId",
        element: (
          <ProtectedRoute module="plot" action="VIEW">
            <EditDocument />
          </ProtectedRoute>
        ),
      },
      {
        path: "/contacts/:userType/add",
        element: (
          <ProtectedRoute module="contact" action="ADD">
            <AddUsers />
          </ProtectedRoute>
        ),
      },
      {
        path: "/contacts/:userType/edit/:index",
        element: (
          <ProtectedRoute module="contact" action="EDIT">
            <EditUsers />
          </ProtectedRoute>
        ),
      },
      {
        path: "/contacts/:type/:index",
        element: (
          <ProtectedRoute module="contact" action="VIEW">
            <ViewUsers />
          </ProtectedRoute>
        ),
      },
      {
        path: "/contacts/:userType/:userId/document/add",
        element: (
          <ProtectedRoute module="contact" action="VIEW">
            <AddDocument />
          </ProtectedRoute>
        ),
      },
      {
        path: "/contacts/:userType/:userId/document/edit/:documentId",
        element: (
          <ProtectedRoute module="contact" action="VIEW">
            <EditDocument />
          </ProtectedRoute>
        ),
      },
      {
        path: "/contacts/:userType/:userId/notes/add",
        element: (
          <ProtectedRoute module="contact" action="VIEW">
            <AddNotes />
          </ProtectedRoute>
        ),
      },
      {
        path: "/contacts/:userType/:userId/notes/edit/:notesId",
        element: (
          <ProtectedRoute module="contact" action="VIEW">
            <EditNotes />
          </ProtectedRoute>
        ),
      },
      {
        path: "/contacts/:userType/:user_id/transaction/add",
        element: (
          <ProtectedRoute module="contact" action="VIEW">
            <AddTransaction />
          </ProtectedRoute>
        ),
      },
      {
        path: "/contacts/:userType/:user_id/transaction/edit/:id",
        element: (
          <ProtectedRoute module="contact" action="VIEW">
            <EditTransaction />
          </ProtectedRoute>
        ),
      },


      {
        path: "projects/add",
        element: (
          <ProtectedRoute module="project" action="ADD">
            <AddSites />
          </ProtectedRoute>
        ),
      },
      {
        path: "/projects/edit/:index",
        element: (
          <ProtectedRoute module="project" action="EDIT">
            <EditSites />
          </ProtectedRoute>
        ),
      },
      {
        path: "/leads/:index",
        element: (
          <ProtectedRoute module="leads" action="VIEW">
            <ViewEnquiry />
          </ProtectedRoute>
        ),
      },
      {
        path: "/leads/add",
        element: (
          <ProtectedRoute module="leads" action="ADD">
            <AddEnquiry />
          </ProtectedRoute>
        ),
      },
      {
        path: "/leads/:id/notes/:source/:sourceKey/add",
        element: (
          <ProtectedRoute module="leads" action="VIEW">
            <EnquiryAddNotes />
          </ProtectedRoute>
        ),
      },
      {
        path: "/plots/:id/notes/:source/:sourceKey/add",
        element: (
          <ProtectedRoute module="leads" action="VIEW">
            <EnquiryAddNotes />
          </ProtectedRoute>
        ),
      },
      {
        path: "/plots/:id/notes/:source/edit/:notesId",
        element: (
          <ProtectedRoute module="plot" action="VIEW">
            <EnquiryEditNotes />
          </ProtectedRoute>
        ),
      },
      {
        path: "/leads/:id/notes/:source/edit/:notesId",
        element: (
          <ProtectedRoute module="leads" action="VIEW">
            <EnquiryEditNotes />
          </ProtectedRoute>
        ),
      },

      {
        path: "/finance/add",
        element: (
          <ProtectedRoute module="accounting" action="ADD">
            <AddTransaction />
          </ProtectedRoute>
        ),
      },
      {
        path: "/finance/edit/:id",
        element: (
          <ProtectedRoute module="accounting" action="EDIT">
            <EditTransaction />
          </ProtectedRoute>
        ),
      },
    ],
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },

  { path: "/", element: <Navigate to="dashboard/default" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
