import React, { useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { patch } from "./../../../../networkConfig/Netwok.js"; // Import API helper methods
import { ENQUIRY } from "./../../../../networkConfig/API_CONSTANTS.js"; // API endpoints

const ProfileTab = ({ enquiryData, updateEnquiyData }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleAccountStatusToggle = async (event) => {
    try {
      const value = event.target.value;

      const payload = { status: value };
      const response = await patch(
        `${ENQUIRY}?id=${enquiryData._id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.code === 200) {
        const _updateEnquiyData = { ...enquiryData, status: value };
        updateEnquiyData(_updateEnquiyData); // Update parent component state
        alert("update status sucessfully");
      } else {
        alert("Failed to update  status");
      }
    } catch (error) {
      console.error("Error updating  status:", error);
    }
  };

  // Submit Bank Details Update

  return (
    <Box sx={{ display: "flex", gap: 3 }}>
      {/* Vertical Tabs */}
      <Tabs
        orientation="vertical"
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        sx={{ borderRight: 1, borderColor: "divider", minWidth: 150 }}
      >
        <Tab label="Basic Info" />
      </Tabs>

      {/* Tab Content */}
      <Box sx={{ flex: 1 }}>
        {/* Basic Info Section */}
        {activeTab === 0 && (
          <Box>
            <Box display="flex" alignItems="center" gap={2} mb={2}>
              <Box>
                <Typography variant="h4">
                  {enquiryData.name ? enquiryData.name : "-"}
                </Typography>
              </Box>
            </Box>

            <Typography variant="subtitle2" fontWeight="bold">
              Contact Information:
            </Typography>
            <Typography>
              Phone: {enquiryData.countryCode} {enquiryData.phoneNumber}
            </Typography>

            <Typography variant="subtitle2" fontWeight="bold" mt={2}>
              Enquiry Status:
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <RadioGroup
                value={enquiryData.status}
                onChange={handleAccountStatusToggle}
                row
              >
                <FormControlLabel value="0" control={<Radio />} label="Open" />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Complete"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Closed"
                />
              </RadioGroup>
            </Stack>

            <Typography variant="subtitle2" fontWeight="bold" mt={2}>
              Expectation Plot Size:
            </Typography>
            <Typography>
              {enquiryData.expectationPlotSize
                ? enquiryData.expectationPlotSize
                : "-"}
            </Typography>

            <Typography variant="subtitle2" fontWeight="bold" mt={2}>
              Expectation Plot budget:
            </Typography>
            <Typography>
              {enquiryData.expectationPlotSize
                ? enquiryData.expectationPlotSize
                : "-"}
            </Typography>

            <Typography variant="subtitle2" fontWeight="bold" mt={2}>
              Locations:
            </Typography>
            <Typography>
              {enquiryData.location ? enquiryData.location : "-"}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProfileTab;
