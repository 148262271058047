import {
  styled
} from "@mui/material";

export const Container = styled("div")(({ theme }) => ({
    margin: "40px",
    [theme.breakpoints.down("sm")]: { margin: "18px" },
    "& .breadcrumb": {
      marginBottom: "40px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" }
    }
  }));
